*{
  margin: 0;
  padding: 0;
  box-sizing: border-box
}

html{
  font-size: 62.5%;
}

body {
  background-color: white !important;
  width: 100%;
}

