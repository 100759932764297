.banner-image{
    width:100%;
    height: auto;
    object-fit: cover;
    position: relative;
}

.banner-image::before{
    content: '';
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.80);
}