.gallery-container{
    background-image: url("../assets/DSC_6664.jpg");
    background-position: 0% -251px;
    padding: 50px 0px;
    background-size: cover;
    position: relative;
}

.gallery-container::before{
    content: '';
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.70);
}

.gallery-title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    line-height: 1.5rem;
    letter-spacing: 0.1rem;
    margin-bottom: 5rem;
    color: white;
    z-index: 2;
    margin-top: 1rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    margin-bottom: 5rem;
    text-transform: uppercase;
}

.gallery-title-border {
    width: 150px;
    height: 1px;
    margin: 10px auto 30px;
    background-color: #e1e1e1;
    display: block;
    position: absolute;
    left: 50%;
    top: 15%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
}

.gallery-title-border span {
    width: 50px;
    height: 2px;
    margin: auto;
    background-color: #a82682;
    display: block;
    position: absolute;
    left: 50%;
    top: 15%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
}

.gallery-card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
    margin-bottom: 3rem;
}

.gallery-card-content{
    width: 30rem;
    background-color: #fff;
    box-shadow: 5px 5px 35px 10px rgba(112, 103, 103, 0.2);
    padding: 10px;
    margin: 2rem;
    border-radius: 1rem;
    overflow: hidden;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    z-index: 2;
    margin-top: 7rem;
}

.gallery-card-imgBox{
    background: #000;
    overflow: hidden;
    position: relative;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
.gallery-card-image{
    width: 100%;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    position: relative;
}

/* .gallery-card:hover .gallery-card-image{
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
} */

.gallery-card-image::before{
    content: '';
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.70);
}

.gallery-card-text{
  padding: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gallery-card-title{
  text-align: center;
  color: black;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0px;
  margin-bottom: 2rem;
}

.gallery-card-about{
  text-align: center;
  color: black;
  font-size: 1.4rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;    
  font-weight: 400;
  height: 200px;
  overflow: hidden;
  flex: 1;
}

.gallery-card-link{
    display: block;
    width: 100%;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.gallery-card-link:hover{
    color: #a82682;
}

.gallery-card-btn{
    background-color: #a82682;
    color: white;
    padding: 9px 20px;
    -webkit-box-shadow: 1px 11px 20px 0px rgb(233 179 14 / 12%);
    box-shadow: 1px 11px 20px 0px rgb(233 179 14 / 12%);
    cursor: pointer;
    border: none;
    outline: none;
    width: 100%;
    font-size: 1.5rem;
    text-transform: uppercase;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}


.gallery-card-btn:hover{
    border: 2px solid  #a82682;
    background-color: #fff;
    color: #a82682;
}

@media(max-width: 425px){
    .gallery-card{
        width: 95vw;
        margin: auto;
        margin-bottom: 2rem;
        margin-top: 8rem;
    }

    .gallery-card-content{
        margin: 0;
        margin-bottom: 1.5rem;
    }

}