.Container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-blend-mode: overlay;
  background-color: #191919;
  padding-top: 2.5rem;
  padding-bottom: 4rem;
}

.Left {
  padding: 20px;
  margin-left: 10px;
}

.Center {
  padding: 20px;
  margin-left: 10px;
}

.Right {
  padding: 20px;
  margin-left: 10px;
}

.SocialContainer {
  display: flex;
}

.SocialIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  background-color: #a82682;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-top: 1.5rem;
  transition: .5s;
  -webkit-transition: .1s;
  -moz-transition: .1s;
  -ms-transition: .1s;
  -o-transition: .1s;
}

.SocialIcon:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform:translateY(-3px);
    -webkit-transform:translateY(-3px);
    -moz-transform:translateY(-3px);
    -ms-transform:translateY(-3px);
    -o-transform:translateY(-3px);
    cursor: pointer;
}

.Title {
  margin-bottom: 1rem;
  color: #fff;
  font-size: 2rem;
  text-transform: uppercase;
}

.List {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  color: #747474;
}

.ListItem {
  min-width:max-content;
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #747474;
  cursor: pointer;
}

.ListItem:hover{
    color: #a82682;
}

.Payment {
    width: 50%;
}

@media screen and (max-width: 529px) {
  .Container {
  flex-direction: column;
  }
}

.footerLink{
  text-decoration: none;
}