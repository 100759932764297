.course-container{
    background-image: url("../assets/courses_offered.jpg");
    background-position: 0% -251px;
    padding: 50px 0px;
    background-size: cover;
    position: relative;
}

.course-container::before{
    content: '';
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.70);
}

.course-card-wrapper{
    display: flex;
    justify-content: center;
    align-self: center;
    flex-wrap: wrap;
    width: 100%;
    margin:auto;
    margin-top: 8rem;
}

.course-card{
    width: 30rem;
    z-index: 1;
    margin-left: 4rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    /* margin-bottom: 4rem; */
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25)
}

.course-card-imgBox{
    /* background: #000; */
    overflow: hidden;
    position: relative;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.course-card-image{
    width: 100%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    position: relative;
}

.course-card:hover .course-card-image{
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}

.course-card-image::before{
    content: '';
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.70);
}

.course-card-text{
  padding: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.course-card-title{
  text-align: center;
  color: black;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0px;
  margin-bottom: 1rem;
}

.course-card-about{
  text-align: center;
  color: black;
  font-size: 1.4rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;    
  font-weight: 400;
  max-height: 100px;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
 display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.course-card-link{
    display: block;
    width: 100%;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.course-card-link:hover{
    color: #a82682;
}

.course-card-btn{
    background-color: #a82682;
    color: white;
    padding: 9px 20px;
    -webkit-box-shadow: 1px 11px 20px 0px rgb(233 179 14 / 12%);
    box-shadow: 1px 11px 20px 0px rgb(233 179 14 / 12%);
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 1.5rem;
    text-transform: uppercase;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}


.course-card-btn:hover{
    border: 2px solid  #a82682;
    background-color: #fff;
    color: #a82682;
}


/* .course-card-btn:hover{
    background-color: rgba(255, 255, 255, 0.12);
} */

@media(max-width:1280px) {
    .course-card-wrapper{
        width: 90%;
    } 
}

@media(max-width:800px) {
    .course-card-wrapper{
        width: 100%;
    } 
}

.course-title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    line-height: 1.5rem;
    letter-spacing: 0.1rem;
    margin-bottom: 1rem;
    color: white;
    z-index: 2;
    margin-top: 1rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.course-title-border {
    width: 150px;
    height: 1px;
    margin: 10px auto 30px;
    background-color: #e1e1e1;
    display: block;
    position: absolute;
    left: 50%;
    top: 13%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
}

.course-title-border span {
    width: 60px;
    height: 3px;
    margin: auto;
    background-color: #a82682;
    display: block;
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
}

@media(max-width: 768px){
    .course-card{
        width: 90vw;
        margin: auto;
        margin-bottom: 2rem;
    }
    
    .course-title{
        line-height: 0;
        letter-spacing: 0;
        font-size: 3rem;
        text-align: center;
        position: absolute;
        width: 90vw;
        margin: auto;
        left: 50%;
    }
    
}