.faqs{
    background-image: url("../assets/faq1.jpeg");
    background-position: 0% -251px;
    padding: 50px 0px;
    background-size: cover;
    object-fit: contain;
    position: relative;
}

.faqs::before{
    content: '';
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.30);
}

.faq-heading {
  color: white;
  margin-bottom: 2rem;
}

.faq-container {
  margin: 0 auto;
  padding: 2rem;
  width: 70%;
  text-align: center;
  opacity: 1;
  position: relative;
  top: 0;
}


.faq-container h2{
    font-size: 3rem;
}

.faq-form {
  display: flex;
  width: 35rem;
  background-color: #fff;
  align-items: center;
  margin: 0 auto;
  border-radius: 1rem;
}
.faq-form  svg {
  height: 1rem;
  fill: #9EA6AE;
  position: fixed;
  width: 4rem;
}

.searchbar {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  border: none;
  padding: 1rem;
  padding-left: 4rem;
  width: 100%;
  box-shadow: 0px 5px 13px 0px #DEE4F1;
  border-radius: 0.8rem;
}

.searchbar:focus {
  outline: none;
  box-shadow: 0 0 0 1.5px #DEE4F1, 0px 5px 13px 0px #DEE4F1;
}

.faq {
  margin: 3rem 0;
}

.question-wrapper {
  width: 90%;
  border-bottom: 1px solid #DEE4F1;
  margin: 0 auto;
  padding: 1rem;
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
}

.question {
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  color: #212943;
  display: flex;
  justify-content: space-between;
}

.question h3{
    font-size: 2rem;
    color: #fff;
}
.question svg {
  width: 2.5rem;
  height: 2.5rem;
  fill: #fff;
  transition: .5s linear;
  -webkit-transition: .5s linear;
  -moz-transition: .5s linear;
  -ms-transition: .5s linear;
  -o-transition: .5s linear;
  cursor: pointer;
}
.question svg.active {
  transform: rotate(180deg);
}
.question svg:hover {
  opacity: 0.8;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.answer {
  display: none;
}

.answer.active {
  display: block;
  text-align: left;
  padding-top: 1.5rem;
  font-weight: light;
  font-size: 0.8rem;
  line-height: 1.5;
  color: #9EA6AE;
  height: 0%;
  animation: slidein 0.4s;
  animation-fill-mode: forwards;
}

.answer.active p{
    font-size: 2rem;
    color: white;
    height: 100px;
    overflow: auto;
}
@keyframes slidein {
  from {
    opacity: 0.3;
    transform: translateY(-20%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media(max-width:850px){
    .faq-container{
        width: 90%;
    }

    .faq-container h2{
        font-size: 3rem;
    }

    .answer.active p{
        font-size: 1.5rem;
    }

    .question h3{
        font-size: 2rem;
    }
}

@media(max-width: 470px){
    .faq-container{
        width: 100vw;
    }
}