/* .allTestimonial-container{
   display:flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   width: 100%;
} */

.carousel-testimonial-container{
   width: 100%;
   margin-bottom: 3rem;
   position: relative;
   padding-top: 2rem;
}

.testimonial-card-wrapper{
  width: 90%;
  margin: auto;
}

.carousel-testimonial-title{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.5rem;
    margin-top: 3rem;
    text-transform: uppercase;

}

.testimonial-card{
    width: 80%;
    height: 25rem;
    min-width: max-content;
    min-height: max-content;
    margin: 80px auto;
    box-shadow: rgba(199, 199, 206, 0.2) 0px 7px 29px 0px;
    /* box-shadow: 2px 2px 35px 10px rgba(112, 103, 103, 0.2); */
    background-color: #fff;
    position: relative;
    padding-top: 30%;
    padding-left: 2%;
    padding-right: 5%;
    padding-bottom: 2%;
    border-top: 5px solid #a82682;
    border-radius: 1rem;
}

.testimonial-image{
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    border-radius: 50%;
    position: absolute;
    top: -20%;
    left: 38%;

}

.testimonial-card-content{
    position: absolute;
    top: 15%;
    right: 4%;
    left: 4%;
    font-size: 1.5rem; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.testimonial-card .people-name{
    text-align: center;
    font-size: 3rem;
    margin-bottom: 1.5rem;
}

.testimonial-card .people-content{
    font-size: 1.6rem;
    color: #445d6e;
}

.testimonial-card .people-job{
    font-size: 1.6rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
    font-weight: bold;
}

.people-content-container{
    height: 10rem;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    width: 1px;
}
 
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.836);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.788); 
}
 
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.767); 
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.801); 
}

.read-testimonials-btn{
    color: black;
    background-color: white;
    box-shadow: 2px 2px 35px 10px rgba(112, 103, 103, 0.2);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25rem;
    margin: auto;
    padding: 1rem .5rem;
    margin-bottom: 3rem;
    margin-top: 2rem;
    font-size: 2rem;
    border-radius: 3px;
    transition: all .5s;
    margin-top: -3rem;
}

.read-testimonials-btn:hover{
    transform: translateY(-6px);
}

@media only screen and (max-width: 760px){

    .testimonial-card{
        width: 30rem;
    } 
    
    .testimonial-card-wrapper{
        width: 90%;
        margin: auto;
    } 

    .testimonial-image{
        width: 7rem;
        height: 7rem;
        position: absolute;
        top: -16%;
        left: 37%;
    }
}

@media only screen and (max-width: 760px){

    .testimonial-card{
        width: 30rem;
    } 
    
    .testimonial-card-wrapper{
        width: 90%;
        margin: auto;
    } 

    .testimonial-image{
        width: 7rem;
        height: 7rem;
        position: absolute;
        top: -16%;
        left: 37%;
    }
}

@media(max-width: 450px){
    .testimonial-card{
        width: 90vw;
    }
}

