.navbar {
    background: white;
    z-index: 999;
    width:100%;
}

.navbar-container {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: .8rem;
    height: 9rem;
    width: 100%;
    z-index: 1000;
}

.logo-img{
    height: 10rem;
    width: 12rem;
    margin-left: 7rem;
}

.sidebar-logo-img{
    display: none;
}

.fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
}

.nav-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 3rem;
    font-size: 1.2rem;
}

.nav-item {
    height: 60px;
    cursor: pointer;
}

.nav-links {
    color: black;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 1rem 1rem;
    height: 100%;
    font-size: 2.5rem;
    margin-left: 1.5rem;
    cursor: pointer;
}

.nav-links:hover {
    border-bottom: 4px solid #a82682;
    color: #a82682;
    transition: all 0.2s ease-out;
}

.fa-bars {
   color: black;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}


@media screen and (max-width: 960px) {
    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding-top: 30px;
        align-items: center;
        min-width: max-content;
        width: 30vw;
        height: 100vh;
        position: absolute;
        top: 10rem;
        left: -100%;
        opacity: 1;
        padding-bottom: 5rem;
        padding-right: 2rem;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        cursor: pointer;
}

    .nav-menu.active {
        background: #fff;
        left: 0;
        top: 9rem;
        opacity: 1;
        transition: all 0.5s ease;
        cursor: pointer;
    }

    .nav-links {
        text-align: center;
        padding: 1rem;
        width: 100%;
        display: table;
        font-size: 2rem;
        cursor: pointer;
    }

    .nav-links:hover {
        border-bottom: 4px solid #a82682;
        color: #a82682;
        transition: all 0.2s ease-out;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 1.5rem;
        right: 2rem;
        font-size: 3rem;
        cursor: pointer;
        cursor: pointer;
    }

    .fa-times {
        color: black;
        font-size: 3rem;
    }
}

@media screen and (max-width: 425px) {
    .logo-img {
        position: absolute;
        top: 5px;
        height: 8rem;
        width: 8rem;
        margin-left: 3rem;
    }

    .menu-icon {
        right: 2rem;
    }
}
