.course-ssc{
    padding: 5rem 1rem;
}

.course-ssc h2{
    text-align: center;
}

.course-ssc-content{
  width: 90%;
  margin: auto;
}

.course-ssc ul{
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.course-svg{
    width: 4rem;
    height: 4rem;
}

.svg-box{
    background-color: rgb(211, 204, 204);
    padding: 2rem;
    width: 8rem;
    height: 8rem;
    border-radius:50%;
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    -ms-border-radius:50%;
    -o-border-radius:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: all 0.5s ease;
}

.ssc-nav-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3rem;
    cursor: pointer;
}

.ssc-nav-item span{
    font-size: 1.5rem;
}

.batch-card-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 3rem;
}

.batch-card{
    width: 35rem;
    height: 25rem;
    overflow-y: auto;
    margin: 2rem;
    padding: 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}

.batch-card:hover{
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
}

.batch-card h2{
    font-size:2.5rem;
    text-align:  center;
    margin: 1.5rem;
    color: #a82682;
}

.batch-card p{
    font-size: 2rem;
    /* height: 150px;
    overflow-y: auto; */
}

.shedule_table{
    box-sizing: border-box;
    border-collapse: collapse;
    border: 1px solid rgb(247, 245, 238);
    box-shadow: 1px 2px 10px #f5a425;
    margin-bottom: 2rem;
}

.shedule_table tr{
    transition: all .2s ease-in;
    cursor: pointer;
}

.shedule_table td,th{
    padding: 12px;
    text-align: left;
    font-size: 1.5rem;
    border: 1px solid black;
}

.shedule_table__header{
    background-color: #a82682;
    color:white;
    border-bottom: 2px solid #ffff;
}

.shedule_table__heading{
    /* width: 900px;
    margin: auto; */
    font-weight: 600;
    text-align: center;
    background-color: #ffff;
    color: #f5a425;
    padding: 10px 0px;
}

.batch-container{
    margin: 2rem;
}

.batch-container h1{
    text-align: center;
    margin-bottom: 1.5rem;
    color: #a82682;
}

.table-container{
    overflow-x: auto;
    width: 90%;
    margin: auto;
}

.table-container::-webkit-scrollbar {
    width: 2px;
}
 
.table-container-testSeries{
   overflow-x: auto;
    width: 90%;
    margin: auto;
    margin-left: 20rem;
}

@media (max-width: 500px){

    .batch-card{
        width: 100vw;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .table-container{
        width: 100vw;
    }

    .course-ssc{
        padding-left: 0rem;
        padding-right: 0rem;
    }

    .shedule_table{
        padding-bottom: 1rem;
    }

    .table-container-testSeries{
        width: 100vw;
        margin-left: 0rem;
    }
}


.VBRBContainer{
    width: 80%;
    margin: auto;
}

.VBRBContainer p{
    font-size: 2rem;
    margin-bottom: 3rem;
}

.ssc-name{
   font-size: 1.5rem;
   font-weight: bold;
   margin-bottom: 2rem;
}

.VBContainer{
    width: 80%;
    margin: auto;
}

.VBContainer p{
    font-size: 2rem;
    margin-bottom: 3rem;
}

.TBContainer{
  width: 80%;
  margin: auto;
}

.TBContainer p{
  font-size: 2rem;
  margin-bottom: 3rem;
}

